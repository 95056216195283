export const PathNames = {
  ADDRESSES: 'addresses',
  CUSTOMERS: 'customers',
  LICENSES: 'licenses',
  USERS: 'users',
  PHONE_NUMBERS: 'phone-numbers'
}

const ApplicationConstants = {
  VAT: 0.081,

  SUPPORTET_LANGUAGES: {
    DE: 'de',
    EN: 'en',
    FR: 'fr',
    IT: 'it'
  },

  prices_new: {
    '1': '0.00 CHF',
    '5': '110.00 CHF',
    '10': '200.00 CHF',
    '20': '380.00 CHF',
    '50': '800.00 CHF',
    '100': '1\'400.00 CHF',
    '200': '2\'600.00 CHF',
    '500': '6\'000.00 CHF',
  },

  QUESTIONNAIRE_VERSIONS: {
    V1: { value: 'V1', display_name: 'Laufbahn-Mosaik v1' },
    V2: { value: 'V2', display_name: 'Laufbahn-Mosaik v2' }
  },

  URL_ADDRESSES_UPDATE_DELETE: `/${PathNames.ADDRESSES}/addressId`,
  URL_CUSTOMERS: `/${PathNames.CUSTOMERS}`,
  URL_CUSTOMERS_EDIT: `/${PathNames.CUSTOMERS}/customerId/edit`,
  URL_CUSTOMERS_UPDATE_DELETE: `/${PathNames.CUSTOMERS}/customerId`,
  URL_CUSTOMERS_TOGGLE_STATUS: `/${PathNames.CUSTOMERS}/customerId/toggle-status`,
  URL_LICENSES: `/${PathNames.LICENSES}`,
  URL_LICENSES_EXPORT: `/${PathNames.LICENSES}/export`,
  URL_LICENSES_UPDATE_DELETE: `/${PathNames.LICENSES}/licenseId`,
  URL_LICENSES_CREATE_FOR_CUSTOMER: `/${PathNames.LICENSES}/create/${PathNames.CUSTOMERS}/customerId`,
  URL_LICENSES_STORE_FOR_CUSTOMER: `/${PathNames.LICENSES}/${PathNames.CUSTOMERS}/customerId`,
  URL_USERS: `/${PathNames.USERS}`,
  URL_PHONE_NUMBERS_UPDATE_DELETE: `/${PathNames.PHONE_NUMBERS}/phoneNumberId`,

}
export default ApplicationConstants